import React, { Component, useRef } from "react";
import { SAGA_ACTIONS, ACTIONS, placehold } from "../../config/config";
import { InputField } from "../../common/form-elements/elements";
import {
  required,
  emailValidator,
  textOnly,
  phoneValidator,
  passwordStrength,
  passwordsMatch,
  numbersOnly,
  usZipCodeValidator,
} from "../../common/validators/form-validators";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";

import moment from "moment";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

import InfiniteScroll from "react-infinite-scroll-component";
import Header from "./header";
import $ from "jquery";
import debounce from "lodash/debounce";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
const errorStyle = {
  fontSize: "12px",
  color: "#f00",
  display: "inline-block",
};
class Playerlisting extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.textInput = React.createRef();
    this.handleInputRangeCss = this.handleInputRangeCss.bind(this);

    this.state = {
      ios: false,
      showQrCode: false,
      showModal: false,
      playerList: [],
      selectsearchDivision: [],
      selectsearchJV: [],
      curPage: 0,
      years: {},
      collegemejors: {},
      states: {},
      stateCode: localStorage.getItem("stateCode")
        ? localStorage.getItem("stateCode")
        : "",

      positions: {},
      divisions: {},
      weight: {
        min: localStorage.getItem("minWeight")
          ? localStorage.getItem("minWeight")
          : 0,
        max: localStorage.getItem("maxWeight")
          ? localStorage.getItem("maxWeight")
          : 0,
      },
      height: {
        min: localStorage.getItem("minHeight")
          ? localStorage.getItem("minHeight")
          : 0,
        max: localStorage.getItem("maxHeight")
          ? localStorage.getItem("maxHeight")
          : 0,
      },
      gpa: {
        min: localStorage.getItem("unweghtedgpa")
          ? localStorage.getItem("unweghtedgpa")
          : 0,
        max: localStorage.getItem("unweghtedgpamax")
          ? localStorage.getItem("unweghtedgpamax")
          : 0,
      },

      searchYear: localStorage.getItem("searchYear")
        ? localStorage.getItem("searchYear")
        : "",
      total: "",
      searchPosition: localStorage.getItem("positionobjectdata")
        ? localStorage.getItem("positionobjectdata")
        : "",
      searchDivision: localStorage.getItem("searchDivision")
        ? JSON.parse(localStorage.getItem("searchDivision"))
        : [],
      searchJV: localStorage.getItem("searchJV")
        ? JSON.parse(localStorage.getItem("searchJV"))
        : [],

      formDocs: {},
      positionobjectdata: {},
      playerMicrositeIds: localStorage.getItem("selectplayer")
        ? localStorage.getItem("selectplayer")
        : "",
      positionobjectdata2: "",
      modal: false,
      coachEmail: "",
      modalinst: localStorage.getItem("dontshowagain") ? false : true,
      unweghted_gpa: localStorage.getItem("unweghtedgpa")
        ? localStorage.getItem("unweghtedgpa")
        : "",
      unweghted_gpa_max: localStorage.getItem("unweghtedgpamax")
        ? localStorage.getItem("unweghtedgpamax")
        : "",

      minHeight: localStorage.getItem("minHeight")
        ? localStorage.getItem("minHeight")
        : "",
      maxHeight: localStorage.getItem("maxHeight")
        ? localStorage.getItem("maxHeight")
        : "",

      minWeight: localStorage.getItem("minWeight")
        ? localStorage.getItem("minWeight")
        : "",
      maxWeight: localStorage.getItem("maxWeight")
        ? localStorage.getItem("maxWeight")
        : "",

      act: localStorage.getItem("act") ? localStorage.getItem("act") : "",
      sat: localStorage.getItem("sat") ? localStorage.getItem("sat") : "",
      points_per_game: localStorage.getItem("points_per_game")
        ? localStorage.getItem("points_per_game")
        : "",
      rebounds_per_game: localStorage.getItem("rebounds_per_game")
        ? localStorage.getItem("rebounds_per_game")
        : "",
      assists_per_game: localStorage.getItem("assists_per_game")
        ? localStorage.getItem("assists_per_game")
        : "",
      steals_per_game: localStorage.getItem("steals_per_game")
        ? localStorage.getItem("steals_per_game")
        : "",
      blocks_per_game: localStorage.getItem("blocks_per_game")
        ? localStorage.getItem("blocks_per_game")
        : "",
      field_goal: localStorage.getItem("field_goal")
        ? localStorage.getItem("field_goal")
        : "",
      pt_fg: localStorage.getItem("pt_fg") ? localStorage.getItem("pt_fg") : "",
      free_throw: localStorage.getItem("free_throw")
        ? localStorage.getItem("free_throw")
        : "",

      major: localStorage.getItem("major") ? localStorage.getItem("major") : "",
      schoolsize: localStorage.getItem("schoolsize")
        ? localStorage.getItem("schoolsize")
        : "",
      slg: localStorage.getItem("slg") ? localStorage.getItem("slg") : "",
      avg: localStorage.getItem("avg") ? localStorage.getItem("avg") : "",
      obp: localStorage.getItem("obp") ? localStorage.getItem("obp") : "",
      time: localStorage.getItem("time") ? localStorage.getItem("time") : "",
      velo: localStorage.getItem("velo") ? localStorage.getItem("velo") : "",

      rh: localStorage.getItem("rh") ? localStorage.getItem("rh") : "",
      lh: localStorage.getItem("lh") ? localStorage.getItem("lh") : "",

      poptime: localStorage.getItem("poptime")
        ? localStorage.getItem("poptime")
        : "",
      strike: localStorage.getItem("strike")
        ? localStorage.getItem("strike")
        : "",
      ip: localStorage.getItem("ip") ? localStorage.getItem("ip") : "",
      era: localStorage.getItem("era") ? localStorage.getItem("era") : "",
      velocity: localStorage.getItem("velocity")
        ? localStorage.getItem("velocity")
        : "",

      setRHP: localStorage.getItem("setRHP")
        ? localStorage.getItem("setRHP")
        : "",
      setLHP: localStorage.getItem("setLHP")
        ? localStorage.getItem("setLHP")
        : "",

      weghted_gpa: "",
      getUnweghtedselectValue: "=",
      getWeghtedselectValue: "=",
      showFilter: false,
    };
  }
  componentDidMount() {
    this.getPlayerList();
    //this.getFilterData();
  }

  getFilterData = () => {
    return new Promise((resolve, reject) => {
      let params = {
        gameId: 5,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_FILTER_DATA,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({ years: res.data.data.years });
          this.setState({ collegemejors: res.data.data.collegemejor });
          this.setState({ states: res.data.data.states });

          this.setState({ divisions: res.data.data.divisions });
          this.setState({ positions: res.data.data.positions });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  getPlayerList = () => {
    return new Promise((resolve, reject) => {
      var gid = "5";
      let params = {
        gameId: gid,

        per_page: "20",
        year: localStorage.getItem("searchYear")
          ? localStorage.getItem("searchYear")
          : "",
        position: localStorage.getItem("positionobjectdata")
          ? localStorage.getItem("positionobjectdata")
          : "",
        stateCode: localStorage.getItem("stateCode")
          ? localStorage.getItem("stateCode")
          : "",

        division: localStorage.getItem("searchDivision")
          ? JSON.parse(localStorage.getItem("searchDivision"))
          : [],
        jvData: localStorage.getItem("searchJV")
          ? JSON.parse(localStorage.getItem("searchJV"))
          : [],
        getUnweghtedselectValue: localStorage.getItem("unweghtedgpa")
          ? localStorage.getItem("unweghtedgpa")
          : "",
        unweghted_gpa: localStorage.getItem("unweghtedgpa")
          ? localStorage.getItem("unweghtedgpa")
          : "",
        unweghted_gpa_max: localStorage.getItem("unweghtedgpamax")
          ? localStorage.getItem("unweghtedgpamax")
          : "",
        minHeight: localStorage.getItem("minHeight")
          ? localStorage.getItem("minHeight")
          : "",
        maxHeight: localStorage.getItem("maxHeight")
          ? localStorage.getItem("maxHeight")
          : "",
        minWeight: localStorage.getItem("minWeight")
          ? localStorage.getItem("minWeight")
          : "",
        maxWeight: localStorage.getItem("maxWeight")
          ? localStorage.getItem("maxWeight")
          : "",

        act: this.state.act ? this.state.act : "",
        sat: this.state.sat ? this.state.sat : "",
        points_per_game: this.state.points_per_game
          ? this.state.points_per_game
          : "",
        rebounds_per_game: this.state.rebounds_per_game
          ? this.state.rebounds_per_game
          : "",
        assists_per_game: this.state.assists_per_game
          ? this.state.assists_per_game
          : "",
        steals_per_game: this.state.steals_per_game
          ? this.state.steals_per_game
          : "",
        blocks_per_game: this.state.blocks_per_game
          ? this.state.blocks_per_game
          : "",
        field_goal: this.state.field_goal ? this.state.field_goal : "",
        pt_fg: this.state.pt_fg ? this.state.pt_fg : "",
        free_throw: this.state.free_throw ? this.state.free_throw : "",

        major: this.state.major ? this.state.major : "",
        schoolsize: this.state.schoolsize ? this.state.schoolsize : "",
        slg: this.state.slg ? this.state.slg : "",
        avg: this.state.avg ? this.state.avg : "",

        obp: this.state.obp ? this.state.obp : "",
        time: this.state.time ? this.state.time : "",
        velo: this.state.velo ? this.state.velo : "",

        rh: this.state.rh ? this.state.rh : "",
        lh: this.state.lh ? this.state.lh : "",

        poptime: this.state.poptime ? this.state.poptime : "",
        strike: this.state.strike ? this.state.strike : "",
        ip: this.state.ip ? this.state.ip : "",
        era: this.state.era ? this.state.era : "",
        velocity: this.state.velocity ? this.state.velocity : "",

        setRHP: this.state.setRHP ? this.state.setRHP : "",
        setLHP: this.state.setLHP ? this.state.setLHP : "",

        getWeghtedselectValue: this.state
          .getUnweghtgetWeghtedselectValueedselectValue
          ? this.state.getWeghtedselectValue
          : "",
        weghted_gpa: this.state.weghted_gpa ? this.state.weghted_gpa : "",
        page: 0,
        playerId: "",
      };
      console.log("===>>>===", params);
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_LIST,
        payload: params,
        callbackSuccess: (res) => {
          this._isMounted = true;
          this.setState({ playerList: res.data.data.players.data });

          this.setState({ curPage: res.data.data.players.current_page });
          this.setState({ total: res.data.data.players.total });

          this.getFilterData();

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };

  handleInputRangeCss(event) {
    var val =
      (event.target.value - event.target.min) /
      (event.target.max - event.target.min);
    event.target.style.setProperty(
      "background-image",
      "-webkit-gradient(linear, left top, right top, " +
        "color-stop(" +
        val +
        ", #090a58), " +
        "color-stop(" +
        val +
        ", #d9d9d9)" +
        ")"
    );
  }

  fetchMoreData = () => {
    document.activeElement.blur();

    if (this._isMounted) {
      return new Promise((resolve, reject) => {
        var gid = "5";

        let params = {
          skip: "0",

          gameId: gid,
          per_page: "20",

          year: localStorage.getItem("searchYear")
            ? localStorage.getItem("searchYear")
            : "",
          stateCode: localStorage.getItem("stateCode")
            ? localStorage.getItem("stateCode")
            : "",

          position: localStorage.getItem("positionobjectdata")
            ? localStorage.getItem("positionobjectdata")
            : "",
          division: localStorage.getItem("searchDivision")
            ? JSON.parse(localStorage.getItem("searchDivision"))
            : [],
          jvData: localStorage.getItem("searchJV")
            ? JSON.parse(localStorage.getItem("searchJV"))
            : [],
          getUnweghtedselectValue: localStorage.getItem("unweghtedgpa")
            ? localStorage.getItem("unweghtedgpa")
            : "",
          unweghted_gpa: localStorage.getItem("unweghtedgpa")
            ? localStorage.getItem("unweghtedgpa")
            : "",
          unweghted_gpa_max: localStorage.getItem("unweghtedgpamax")
            ? localStorage.getItem("unweghtedgpamax")
            : "",
          minHeight: localStorage.getItem("minHeight")
            ? localStorage.getItem("minHeight")
            : "",
          maxHeight: localStorage.getItem("maxHeight")
            ? localStorage.getItem("maxHeight")
            : "",
          minWeight: localStorage.getItem("minWeight")
            ? localStorage.getItem("minWeight")
            : "",
          maxWeight: localStorage.getItem("maxWeight")
            ? localStorage.getItem("maxWeight")
            : "",

          avg: this.state.avg ? this.state.avg : "",

          act: this.state.act ? this.state.act : "",
          sat: this.state.sat ? this.state.sat : "",
          points_per_game: this.state.points_per_game
            ? this.state.points_per_game
            : "",
          rebounds_per_game: this.state.rebounds_per_game
            ? this.state.rebounds_per_game
            : "",
          assists_per_game: this.state.assists_per_game
            ? this.state.assists_per_game
            : "",
          steals_per_game: this.state.steals_per_game
            ? this.state.steals_per_game
            : "",
          blocks_per_game: this.state.blocks_per_game
            ? this.state.blocks_per_game
            : "",
          field_goal: this.state.field_goal ? this.state.field_goal : "",
          pt_fg: this.state.pt_fg ? this.state.pt_fg : "",
          free_throw: this.state.free_throw ? this.state.free_throw : "",

          major: this.state.major ? this.state.major : "",
          schoolsize: this.state.schoolsize ? this.state.schoolsize : "",
          slg: this.state.slg ? this.state.slg : "",
          obp: this.state.obp ? this.state.obp : "",
          time: this.state.time ? this.state.time : "",
          velo: this.state.velo ? this.state.velo : "",

          rh: this.state.rh ? this.state.rh : "",
          lh: this.state.lh ? this.state.lh : "",

          poptime: this.state.poptime ? this.state.poptime : "",
          strike: this.state.strike ? this.state.strike : "",
          ip: this.state.ip ? this.state.ip : "",
          era: this.state.era ? this.state.era : "",
          velocity: this.state.velocity ? this.state.velocity : "",

          setRHP: this.state.setRHP ? this.state.setRHP : "",
          setLHP: this.state.setLHP ? this.state.setLHP : "",

          getWeghtedselectValue: this.state
            .getUnweghtgetWeghtedselectValueedselectValue
            ? this.state.getWeghtedselectValue
            : "",
          weghted_gpa: this.state.weghted_gpa ? this.state.weghted_gpa : "",
          playerId: "",
          page: this.state.curPage + 1,
        };
        this.props.dispatch({
          type: SAGA_ACTIONS.USER.PLAYER_LIST,
          payload: params,
          callbackSuccess: (res) => {
            this.setState({
              playerList: this.state.playerList.concat(
                res.data.data.players.data
              ),
            });

            this.setState({ curPage: res.data.data.players.current_page });

            resolve();
          },
          callbackError: (err) => {
            //login error
            toastr.error(
              "Error",
              err && err.data && err.data.Message
                ? err.data.Message
                : "There are some thing wrong"
            );
            reject();
          },
        });
      });
    }
  };

  selectDivision = (u) => {
    let arr = this.state.searchDivision;
    if (this.state.searchDivision.includes(u) == true) {
      arr = arr.filter((item) => item != u);
    } else {
      arr.push(u);
    }
    // this.setState({searchDivision:arr},()=>{
    // 	this.loadInitialDataHandler();
    // });

    this.setState({ searchDivision: arr, curPage: 0 });
    this.setState({ selectsearchDivision: this.state.searchDivision });

    // this.setState({ searchJV: [] });
    ///localStorage.removeItem("searchJV");
  };
  selectJV = (v) => {
    let arr2 = this.state.searchJV;
    if (this.state.searchJV.includes(v) == true) {
      arr2 = arr2.filter((item) => item != v);
    } else {
      arr2.push(v);
    }

    this.setState({ searchJV: arr2, curPage: 0 });
    this.setState({ selectsearchJV: this.state.searchJV });
  };

  clearData = () => {
    this.setState({
      searchYear: "",
      searchPosition: "",
      searchDivision: [],
      searchJV: [],

      curPage: 0,
      playerMicrositeIds: {},
      positionobjectdata2: {},
      modal: false,
      positionobjectdata: {},
      coachEmail: "",
      formDocs: {},
      unweghted_gpa: "",
      unweghted_gpa_max: "",
      minHeight: "",
      maxHeight: "",
      minWeight: "",
      maxWeight: "",

      act: "",
      sat: "",
      points_per_game: "",
      rebounds_per_game: "",
      assists_per_game: "",
      steals_per_game: "",
      blocks_per_game: "",
      field_goal: "",
      pt_fg: "",
      free_throw: "",
      major: "",
      schoolsize: "",
      slg: "",
      avg: "",
      obp: "",
      time: "",
      velo: "",

      rh: "",
      lh: "",
      poptime: "",
      strike: "",
      ip: "",
      era: "",
      velocity: "",
      stateCode: "",

      setRHP: "",
      setLHP: "",
      weight: { min: 0, max: 0 },
      height: { min: 0, max: 0 },

      gpa: { min: 0, max: 0 },

      weghted_gpa: localStorage.getItem("unweghtedgpa")
        ? localStorage.getItem("unweghtedgpa")
        : "",
    });
    localStorage.removeItem("positionobjectdata");
    localStorage.removeItem("searchDivision");
    localStorage.removeItem("searchJV");
    localStorage.removeItem("stateCode");

    localStorage.removeItem("searchYear");
    localStorage.removeItem("unweghtedgpa");
    localStorage.removeItem("unweghtedgpamax");

    localStorage.removeItem("minWeight");
    localStorage.removeItem("maxWeight");

    localStorage.removeItem("minHeight");
    localStorage.removeItem("maxHeight");

    setTimeout(() => {
      this.getPlayerList();
    }, 600);
  };

  // handleChange2 = (e, name) => {
  //   let s = this.state.formDocs;
  //   if (e.target.checked) {
  //     let prevStr = s[name];

  //     if (!prevStr) {
  //       s[name] = e.target.value;
  //     } else {
  //       s[name] = [prevStr, e.target.value].join(",");
  //     }
  //   } else {
  //     let prevStr = this.state.formDocs;
  //     s[name] = localStorage.getItem("searchYear")
  //     ? localStorage.getItem("searchYear")
  //       .split(",")
  //       .filter((x) => x !== e.target.value)
  //       .join(","):{};
  //     this.setState({ formDocs: s });
  //   }

  //   var c = this.state.formDocs;
  //   var v = Object.values(c).join(",");
  //   setTimeout(() => {
  //     this.setState({ searchYear: v });

  //     //this.clearData();
  //     // this.getPlayerList();
  //   }, 600);
  // };
  //   handleChange = (e, name) => {

  //     let s = this.state.positionobjectdata;
  //     console.log("===yyyy====>>>>",s);
  //     if (e.target.checked) {
  //       let prevStr = s[name];

  //       if (!prevStr) {
  //         s[name] = e.target.value;
  //       } else {
  //         s[name] = [prevStr, e.target.value].join(",");
  //       }
  //     } else {
  // //  let sr = localStorage.getItem("positionobjectdata").split(",");
  // //  let st ={};
  // //  st = sr.reduce((a, v) => ({ ...a, [v]: v}), {});

  // //        console.log("uncheck==123==>>>",st);
  // //       //let prevStr = st;
  //       s[name] = localStorage.getItem("positionobjectdata").split(",").filter((x) => x !== e.target.value).join(",");
  //       this.setState({ positionobjectdata: s });
  //     }
  //     console.log("===ddddddddd>>>>==",this.state.positionobjectdata);
  //     var cs = this.state.positionobjectdata;
  //     var vs = Object.values(cs).join(",");
  //     console.log("===ddddddddd777777>>>>==",vs);

  //     setTimeout(() => {
  //       this.setState({ searchPosition: vs, curPage: 0 });

  //       //this.clearData();
  //       // this.getPlayerList();
  //     }, 600);
  //   };
  removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };
  handleChange2 = (e, name) => {
    if (e.target.checked) {
      var p = [];
      if (localStorage.getItem("searchYear")) {
        p = [localStorage.getItem("searchYear"), e.target.value].join(",");
        localStorage.setItem("searchYear", p);
      } else {
        localStorage.setItem("searchYear", e.target.value);
      }
    } else {
      let position = localStorage
        .getItem("searchYear")
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");
      localStorage.setItem("searchYear", position);
    }
    setTimeout(() => {
      this.setState({ searchYear: localStorage.getItem("searchYear") });

      //this.clearData();
      // this.getPlayerList();
    }, 600);
  };
  handleChange = (e, name) => {
    if (e.target.checked) {
      var p = [];
      if (localStorage.getItem("positionobjectdata")) {
        p = [localStorage.getItem("positionobjectdata"), e.target.value].join(
          ","
        );
        localStorage.setItem("positionobjectdata", p);
      } else {
        localStorage.setItem("positionobjectdata", e.target.value);
      }
    } else {
      let position = localStorage
        .getItem("positionobjectdata")
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");
      localStorage.setItem("positionobjectdata", position);
      if (e.target.value == "Pitching") {
        localStorage.removeItem("setRHP");
        localStorage.removeItem("setLHP");
        localStorage.removeItem("strike");
        localStorage.removeItem("ip");
        localStorage.removeItem("era");
        localStorage.removeItem("velocity");
        this.setState({
          setRHP: "",
        });
        this.setState({ setLHP: "" });
        this.setState({ strike: "" });
        this.setState({ ip: "" });
        this.setState({ era: "" });
        this.setState({ velocity: "" });
      }
      if (e.target.value == "Catching") {
        localStorage.removeItem("poptime");
        this.setState({ poptime: "" });
      }
    }
    setTimeout(() => {
      this.setState({
        searchPosition: localStorage.getItem("positionobjectdata"),
        curPage: 0,
      });
    }, 600);
  };
  handleChangePlayer = (e, name) => {
    let s = this.state.positionobjectdata2;
    if (e.target.checked) {
      var arr = [];
      if (localStorage.getItem("selectplayer")) {
        arr = [localStorage.getItem("selectplayer"), e.target.value].join(",");

        localStorage.setItem("selectplayer", arr);
      } else {
        localStorage.setItem("selectplayer", e.target.value);
      }
    } else {
      let arrPlayer = localStorage
        .getItem("selectplayer")
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");

      // let prevStr = s[name];
      // s[name] = prevStr
      //   .split(",")
      //   .filter((x) => x !== e.target.value)
      //   .join(",");
      localStorage.setItem("selectplayer", arrPlayer);
    }

    setTimeout(() => {
      this.setState({
        playerMicrositeIds: localStorage.getItem("selectplayer"),
      });
    }, 600);
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  sendMicrositeMail = () => {
    return new Promise((resolve, reject) => {
      let params = {
        email: this.state.coachEmail,
        player_id: this.state.playerMicrositeIds,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_MICROSITE_LIST,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({
            modal: false,
            coachEmail: "",
          });
          toastr.success("Success", res.data.message);
          localStorage.removeItem("selectplayer");

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.message
              ? err.data.message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  handleFocus = () => {
    // this.textInput.current.blur(); // removing focus
  };
  closeInst = () => {
    this.setState({
      modalinst: false,
    });

    if (
      !localStorage.getItem("positionobjectdata") &&
      !localStorage.getItem("searchDivision") &&
      !localStorage.getItem("searchYear") &&
      !localStorage.getItem("unweghtedgpa")
    ) {
      this.setState({ showFilter: true });
    }
  };
  getUnweghtedselectValue = debounce((value) => {
    this.setState({ getUnweghtedselectValue: value });
    if (this.state.unweghted_gpa) {
      // this.getPlayerList();
    }
  }, 1500);
  getWeghtedselectValue = debounce((value) => {
    this.setState({ getWeghtedselectValue: value });
    if (this.state.weghted_gpa) {
      // this.getPlayerList();
    }
  }, 1500);
  unweghtedgpa = (value) => {
    if (value.match(/^([1-4]+)?([.]?[1-9]{0,1})?$/)) {
      // localStorage.setItem("unweghtedgpa", value);

      if (value < 5) {
        this.setState({
          unweghted_gpa: value,
        });
        $("#msg").html("");
      }
    } else {
      $("#msg").html("In-valid input!!");
    }
    // localStorage.setItem("unweghtedgpa",value);

    // this.setState({
    //   unweghted_gpa: value,
    // });
    //this.getPlayerList();
  };

  minHeightData = (value) => {
    this.setState({
      minHeight: value,
    });
  };
  maxHeightData = (value) => {
    this.setState({
      maxHeight: value,
    });
  };

  minWeightData = (value) => {
    this.setState({
      minWeight: value,
    });
  };
  actData = (value) => {
    this.setState({
      act: value,
    });
  };
  satData = (value) => {
    this.setState({
      sat: value,
    });
  };
  points_per_gameData = (value) => {
    this.setState({
      points_per_game: value,
    });
  };
  rebounds_per_gameData = (value) => {
    this.setState({
      rebounds_per_game: value,
    });
  };
  assists_per_gameData = (value) => {
    this.setState({
      assists_per_game: value,
    });
  };
  steals_per_gameData = (value) => {
    this.setState({
      steals_per_game: value,
    });
  };
  blocks_per_gameData = (value) => {
    this.setState({
      blocks_per_game: value,
    });
  };
  field_goalData = (value) => {
    this.setState({
      field_goal: value,
    });
  };

  pt_fgData = (value) => {
    this.setState({
      pt_fg: value,
    });
  };
  free_throwData = (value) => {
    this.setState({
      free_throw: value,
    });
  };

  majorData = (value) => {
    this.setState({
      major: value,
    });
  };
  stateData = (value) => {
    this.setState({
      stateCode: value,
    });
  };

  weghtedgpa = debounce((value) => {
    this.setState({
      weghted_gpa: value,
    });

    // this.getPlayerList();
  }, 1500);
  applyFilter = () => {
    console.log(">>>>>,,>>>>", this.state.searchDivision);
    setTimeout(() => {
      localStorage.setItem(
        "unweghtedgpa",
        this.state.unweghted_gpa ? this.state.unweghted_gpa : ""
      );
      // localStorage.setItem(
      //   "unweghtedgpamax",
      //   this.state.gpa && this.state.gpa.min
      //     ? Number(this.state.gpa.max).toFixed(1)
      //     : ""
      // );
      localStorage.setItem(
        "stateCode",
        this.state.stateCode ? this.state.stateCode : ""
      );

      localStorage.setItem(
        "minWeight",
        this.state.minWeight && this.state.minWeight ? this.state.minWeight : ""
      );
      localStorage.setItem(
        "maxWeight",
        this.state.weight && this.state.weight.max ? this.state.weight.max : ""
      );
      localStorage.setItem("minHeight", this.state.minHeight);
      // localStorage.setItem(
      //   "minHeight",
      //   this.state.height && this.state.height.min ? this.state.height.min : ""
      // );
      localStorage.setItem("maxHeight", this.state.maxHeight);

      localStorage.setItem("act", this.state.act);
      localStorage.setItem("sat", this.state.sat);
      localStorage.setItem("points_per_game", this.state.points_per_game);
      localStorage.setItem("rebounds_per_game", this.state.rebounds_per_game);
      localStorage.setItem("assists_per_game", this.state.assists_per_game);
      localStorage.setItem("steals_per_game", this.state.steals_per_game);
      localStorage.setItem("blocks_per_game", this.state.blocks_per_game);
      localStorage.setItem("field_goal", this.state.field_goal);
      localStorage.setItem("pt_fg", this.state.pt_fg);
      localStorage.setItem("free_throw", this.state.free_throw);

      localStorage.setItem("major", this.state.major);
      localStorage.setItem("schoolsize", this.state.schoolsize);
      localStorage.setItem("slg", this.state.slg);
      localStorage.setItem("avg", this.state.avg);
      localStorage.setItem("obp", this.state.obp);
      localStorage.setItem("time", this.state.time);
      localStorage.setItem("velo", this.state.velo);

      localStorage.setItem("rh", this.state.rh);
      localStorage.setItem("lh", this.state.lh);

      localStorage.setItem("poptime", this.state.poptime);
      localStorage.setItem("strike", this.state.strike);
      localStorage.setItem("ip", this.state.ip);
      localStorage.setItem("era", this.state.era);
      localStorage.setItem("velocity", this.state.velocity);

      localStorage.setItem("setRHP", this.state.setRHP);
      localStorage.setItem("setLHP", this.state.setLHP);

      localStorage.setItem("positionobjectdata", this.state.searchPosition);
      localStorage.setItem("searchYear", this.state.searchYear);

      localStorage.setItem(
        "searchDivision",
        JSON.stringify(this.state.searchDivision)
      );
      localStorage.setItem("searchJV", JSON.stringify(this.state.searchJV));
    }, 600);

    setTimeout(() => {
      this.getPlayerList();
    }, 800);
    this.setState({ showFilter: false });
  };
  render() {
    const { handleSubmit, submitting } = this.props;
    console.log("===>>>>", this.state.weight);
    let new_ht = "";
    if (
      localStorage.getItem("minHeight") &&
      localStorage.getItem("minHeight") !== null
    ) {
      if (localStorage.getItem("minHeight").includes(".")) {
        const ht = localStorage.getItem("minHeight").split(".");
        if (ht[1]) {
          new_ht = ht[0] + "’ " + ht[1].replace(/^0+/, "") + "”";
        } else {
          new_ht = localStorage.getItem("minHeight") + "’";
        }
      } else {
        new_ht = localStorage.getItem("minHeight") + "’";
      }
    }
    return (
      <div>
        <Header />

        <Modal isOpen={this.state.showFilter} centered>
          {/* <ModalHeader toggle={this.props.close}></ModalHeader> */}
          <div className="popup_icon">
            <img src="../../images/filter_icon.png" />
          </div>
          <ModalBody>
            <div className="filter_ctrl">
              <h3>Filter</h3>
              {this.state.divisions && this.state.divisions.length > 0 && (
                <div className="year_list positionfilter fullwidth">
                  <h4>Division</h4>
                  <div className="d-flex mb-3">
                    {this.state.divisions && this.state.divisions.length
                      ? this.state.divisions.map((w, e) => {
                          var cVal = "";
                          var cJV = "";
                          var showJV = false;

                          if (this.state.searchDivision == w.key_value) {
                            cVal = "checked";
                          } else {
                            cVal = "";
                          }
                          if (this.state.searchJV == w.key_value) {
                            cJV = "checked";
                          } else {
                            cJV = "";
                          }
                          if (
                            w.key_value == "d3" ||
                            w.key_value == "naia" ||
                            w.key_value == "d2"
                          ) {
                            showJV = true;
                          }

                          return (
                            <div className="form-check me-3 divisionjv" key={e}>
                              <label
                                className="form-check-label"
                                htmlhtmlFor="flexRadioDefault1"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={"division[" + w.keyValue + "]"}
                                  onClick={(e) =>
                                    this.selectDivision(w.key_value)
                                  }
                                  readOnly
                                  checked={
                                    this.state.searchDivision &&
                                    this.state.searchDivision.indexOf(
                                      w.key_value
                                    ) >= 0
                                      ? "checked"
                                      : ""
                                  }
                                />
                                {w.name}
                              </label>

                              {showJV ? (
                                <label
                                  className="form-check-label"
                                  htmlhtmlFor="flexRadioDefault11"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={"jv[" + w.keyValue + "]"}
                                    onClick={(e) => this.selectJV(w.key_value)}
                                    readOnly
                                    checked={
                                      this.state.searchJV &&
                                      this.state.searchJV.indexOf(
                                        w.key_value
                                      ) >= 0
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                  JV
                                </label>
                              ) : (
                                <label
                                  className="form-check-label"
                                  htmlhtmlFor="flexRadioDefault11"
                                >
                                  &nbsp;
                                </label>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              )}
              {this.state.positions && this.state.positions.length > 0 && (
                <div className="year_list positionfilter fullwidth">
                  <h4>Player Primary Position</h4>

                  <ul className="checkbox_btn">
                    {this.state.positions && this.state.positions.length
                      ? this.state.positions.map((t, s) => {
                          return (
                            <li className="form-check" key={s}>
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={t.value}
                                  name={t.value}
                                  onClick={(e) => this.handleChange(e, t.value)}
                                  checked={
                                    this.state.searchPosition &&
                                    this.state.searchPosition
                                      .split(",")
                                      .indexOf(t.value) != -1
                                      ? "checked"
                                      : ""
                                  }
                                />
                                <span>{t.key}</span>
                              </label>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              )}

              <div className="year_list positionfilter fullwidth">
                <h4>Graduation Year</h4>

                <ul className="checkbox_btn">
                  {this.state.years && this.state.years.length
                    ? this.state.years.map((v, u) => {
                        return (
                          <li key={u} className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={v}
                                name={v}
                                onChange={(e) => this.handleChange2(e, v)}
                                checked={
                                  this.state.searchYear &&
                                  this.state.searchYear.indexOf(v) != -1
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span>{v}</span>
                            </label>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Unweighted GPA</h4>

                <div className="row plheight2">
                  <div className="col-lg-12 col-md-12 col-12">
                    {/* <InputRange
                      step={0.1}
                      maxValue={5.0}
                      minValue={2.0}
                      formatLabel={(value) => `${Number(value).toFixed(1)}`}
                      value={this.state.gpa}
                      onChange={(value) => this.setState({ gpa: value })}
                    /> */}
                    <input
                      type="text"
                      name="unweighted_gpa"
                      placeholder="eg. 3.0"
                      onChange={(e) => this.unweghtedgpa(e.target.value)}
                      className="form-control"
                      value={
                        this.state.unweghted_gpa ? this.state.unweghted_gpa : ""
                      }
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Height</h4>

                <div className="row heightSelect">
                  <div className="col-lg-12 col-md-12 col-12 ps-0">
                    {/* <InputRange
                      step={1}
                      maxValue={7}
                      minValue={1}
                      value={this.state.height}
                      onChange={(value) => this.setState({ height: value })}
                    /> */}
                    {/* <input
                      type="text"
                      name="minHeight"
                      onChange={(e) => this.minHeightData(e.target.value)}
                      className="form-control"
                      value={this.state.minHeight ? this.state.minHeight : ""}
                    /> */}
                    <select
                      className="form-control"
                      onChange={(e) => this.minHeightData(e.target.value)}
                    >
                      <option value="">-Select-</option>

                      <option
                        value="5"
                        selected={
                          localStorage.getItem("minHeight") == "5"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5'{" "}
                      </option>
                      <option
                        value="5.01"
                        selected={
                          localStorage.getItem("minHeight") == "5.01"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 1"{" "}
                      </option>
                      <option
                        value="5.02"
                        selected={
                          localStorage.getItem("minHeight") == "5.02"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 2"{" "}
                      </option>
                      <option
                        value="5.03"
                        selected={
                          localStorage.getItem("minHeight") == "5.03"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 3"{" "}
                      </option>
                      <option
                        value="5.04"
                        selected={
                          localStorage.getItem("minHeight") == "5.04"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 4"{" "}
                      </option>
                      <option
                        value="5.05"
                        selected={
                          localStorage.getItem("minHeight") == "5.05"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 5"{" "}
                      </option>
                      <option
                        value="5.06"
                        selected={
                          localStorage.getItem("minHeight") == "5.06"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 6"{" "}
                      </option>
                      <option
                        value="5.07"
                        selected={
                          localStorage.getItem("minHeight") == "5.07"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 7"{" "}
                      </option>
                      <option
                        value="5.08"
                        selected={
                          localStorage.getItem("minHeight") == "5.08"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 8"{" "}
                      </option>
                      <option
                        value="5.09"
                        selected={
                          localStorage.getItem("minHeight") == "5.09"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 9"{" "}
                      </option>
                      <option
                        value="5.10"
                        selected={
                          localStorage.getItem("minHeight") == "5.10"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 10"{" "}
                      </option>
                      <option
                        value="5.11"
                        selected={
                          localStorage.getItem("minHeight") == "5.11"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        5' 11"{" "}
                      </option>

                      <option
                        value="6"
                        selected={
                          localStorage.getItem("minHeight") == "6"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6'{" "}
                      </option>
                      <option
                        value="6.01"
                        selected={
                          localStorage.getItem("minHeight") == "6.01"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 1"{" "}
                      </option>
                      <option
                        value="6.02"
                        selected={
                          localStorage.getItem("minHeight") == "6.02"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 2"{" "}
                      </option>
                      <option
                        value="6.03"
                        selected={
                          localStorage.getItem("minHeight") == "6.03"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 3"{" "}
                      </option>
                      <option
                        value="6.04"
                        selected={
                          localStorage.getItem("minHeight") == "6.04"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 4"{" "}
                      </option>
                      <option
                        value="6.05"
                        selected={
                          localStorage.getItem("minHeight") == "6.05"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 5"{" "}
                      </option>
                      <option
                        value="6.06"
                        selected={
                          localStorage.getItem("minHeight") == "6.06"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 6"{" "}
                      </option>
                      <option
                        value="6.07"
                        selected={
                          localStorage.getItem("minHeight") == "6.07"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 7"{" "}
                      </option>
                      <option
                        value="6.08"
                        selected={
                          localStorage.getItem("minHeight") == "6.08"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 8"{" "}
                      </option>
                      <option
                        value="6.09"
                        selected={
                          localStorage.getItem("minHeight") == "6.09"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 9"{" "}
                      </option>
                      <option
                        value="6.10"
                        selected={
                          localStorage.getItem("minHeight") == "6.10"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 10"{" "}
                      </option>
                      <option
                        value="6.11"
                        selected={
                          localStorage.getItem("minHeight") == "6.11"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        6' 11"{" "}
                      </option>
                      <option
                        value="7"
                        selected={
                          localStorage.getItem("minHeight") == "7"
                            ? "selected"
                            : ""
                        }
                      >
                        {" "}
                        7'{" "}
                      </option>
                    </select>
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-6 ps-0">
                    <select
                      className="form-control"
                      onChange={(e) => this.maxHeightData(e.target.value)}
                    >
                      <option value="">-Select-</option>

                      <option
                        value="0"
                        selected={
                          localStorage.getItem("maxHeight") == 0
                            ? "selected"
                            : ""
                        }
                      >
                        0 (in)
                      </option>
                      <option
                        value="1"
                        selected={
                          localStorage.getItem("maxHeight") == 1
                            ? "selected"
                            : ""
                        }
                      >
                        1 (in)
                      </option>
                      <option
                        value="2"
                        selected={
                          localStorage.getItem("maxHeight") == 2
                            ? "selected"
                            : ""
                        }
                      >
                        2 (in)
                      </option>
                      <option
                        value="3"
                        selected={
                          localStorage.getItem("maxHeight") == 3
                            ? "selected"
                            : ""
                        }
                      >
                        3 (in)
                      </option>
                      <option
                        value="4"
                        selected={
                          localStorage.getItem("maxHeight") == 4
                            ? "selected"
                            : ""
                        }
                      >
                        4 (in)
                      </option>
                      <option
                        value="5"
                        selected={
                          localStorage.getItem("maxHeight") == 5
                            ? "selected"
                            : ""
                        }
                      >
                        5 (in)
                      </option>
                      <option
                        value="6"
                        selected={
                          localStorage.getItem("maxHeight") == 6
                            ? "selected"
                            : ""
                        }
                      >
                        6 (in)
                      </option>
                      <option
                        value="7"
                        selected={
                          localStorage.getItem("maxHeight") == 7
                            ? "selected"
                            : ""
                        }
                      >
                        7 (in)
                      </option>
                      <option
                        value="8"
                        selected={
                          localStorage.getItem("maxHeight") == 8
                            ? "selected"
                            : ""
                        }
                      >
                        8 (in)
                      </option>
                      <option
                        value="9"
                        selected={
                          localStorage.getItem("maxHeight") == 9
                            ? "selected"
                            : ""
                        }
                      >
                        9 (in)
                      </option>
                      <option
                        value="10"
                        selected={
                          localStorage.getItem("maxHeight") == 10
                            ? "selected"
                            : ""
                        }
                      >
                        10 (in)
                      </option>
                      <option
                        value="11"
                        selected={
                          localStorage.getItem("maxHeight") == 11
                            ? "selected"
                            : ""
                        }
                      >
                        11 (in)
                      </option>
                    </select>
                    <div id="msg" style={{ color: "red" }}></div>
                  </div> */}
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Weight</h4>

                <div className="row plheight2">
                  <div className="col-lg-12 col-md-12 col-12">
                    {/* <InputRange
                      step={1}
                      maxValue={300}
                      minValue={30}
                      value={this.state.weight}
                      onChange={(value) => this.setState({ weight: value })}
                    /> */}

                    <input
                      type="number"
                      placeholder="eg. 100 lbs"
                      name="minWeight"
                      onChange={(e) => this.minWeightData(e.target.value)}
                      className="form-control"
                      value={this.state.minWeight ? this.state.minWeight : ""}
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum ACT</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="act"
                      placeholder="eg: 21"
                      onChange={(e) => this.actData(e.target.value)}
                      className="form-control"
                      value={this.state.act ? this.state.act : ""}
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum SAT</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="sat"
                      placeholder="eg: 1000"
                      onChange={(e) => this.satData(e.target.value)}
                      className="form-control"
                      value={this.state.sat ? this.state.sat : ""}
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>State</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <select
                      className="form-control"
                      onChange={(e) => this.stateData(e.target.value)}
                    >
                      <option value="">-select-</option>
                      {this.state.states && this.state.states.length
                        ? this.state.states.map((m, n) => {
                            return (
                              <option
                                key={n}
                                value={m.state_code}
                                selected={
                                  this.state.stateCode == m.state_code
                                    ? "selected"
                                    : ""
                                }
                              >
                                {m.state}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {/* <input
                      type="text"
                      name="major"
                      onChange={(e) => this.majorData(e.target.value)}
                      className="form-control"
                      value={this.state.major ? this.state.major : ""}
                    /> */}
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Planned College Major</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <select
                      className="form-control"
                      onChange={(e) => this.majorData(e.target.value)}
                    >
                      <option value="">-select-</option>
                      {this.state.collegemejors &&
                      this.state.collegemejors.length
                        ? this.state.collegemejors.map((m, n) => {
                            return (
                              <option
                                key={n}
                                value={m}
                                selected={
                                  this.state.major == m ? "selected" : ""
                                }
                              >
                                {m}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {/* <input
                      type="text"
                      name="major"
                      onChange={(e) => this.majorData(e.target.value)}
                      className="form-control"
                      value={this.state.major ? this.state.major : ""}
                    /> */}
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Points Per Game</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="points_per_game"
                      placeholder="eg: 10"
                      onChange={(e) => this.points_per_gameData(e.target.value)}
                      className="form-control"
                      value={
                        this.state.points_per_game
                          ? this.state.points_per_game
                          : ""
                      }
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Rebounds Per Game</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="rebounds_per_game"
                      placeholder="eg: 5"
                      onChange={(e) =>
                        this.rebounds_per_gameData(e.target.value)
                      }
                      className="form-control"
                      value={
                        this.state.rebounds_per_game
                          ? this.state.rebounds_per_game
                          : ""
                      }
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Assists Per Game</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="assists_per_game"
                      placeholder="eg: 5"
                      onChange={(e) =>
                        this.assists_per_gameData(e.target.value)
                      }
                      className="form-control"
                      value={
                        this.state.assists_per_game
                          ? this.state.assists_per_game
                          : ""
                      }
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Steals Per Game</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="steals_per_game"
                      placeholder="eg: 1"
                      onChange={(e) => this.steals_per_gameData(e.target.value)}
                      className="form-control"
                      value={
                        this.state.steals_per_game
                          ? this.state.steals_per_game
                          : ""
                      }
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum Blocks Per Game</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="blocks_per_game"
                      placeholder="eg: 1"
                      onChange={(e) => this.blocks_per_gameData(e.target.value)}
                      className="form-control"
                      value={
                        this.state.blocks_per_game
                          ? this.state.blocks_per_game
                          : ""
                      }
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum FG%</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="field_goal"
                      placeholder="eg: 45%"
                      onChange={(e) => this.field_goalData(e.target.value)}
                      className="form-control"
                      value={this.state.field_goal ? this.state.field_goal : ""}
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>

              <div className="year_list positionfilter">
                <h4>Minimum 3PT FG%</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="pt_fg"
                      placeholder="eg: 40%"
                      onChange={(e) => this.pt_fgData(e.target.value)}
                      className="form-control"
                      value={this.state.pt_fg ? this.state.pt_fg : ""}
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>
              <div className="year_list positionfilter">
                <h4>Minimum Free Throw %</h4>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <input
                      type="number"
                      name="free_throw"
                      placeholder="eg: 70%"
                      onChange={(e) => this.free_throwData(e.target.value)}
                      className="form-control"
                      value={this.state.free_throw ? this.state.free_throw : ""}
                    />
                    <div id="msg" style={{ color: "red" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={(e) => this.applyFilter()}>
              Apply
            </Button>
            <Button color="secondary" onClick={(e) => this.clearData()}>
              Reset
            </Button>
            <Button
              color="danger"
              onClick={(e) =>
                this.setState({
                  showFilter: false,
                  searchDivision: [],
                  searchJV: [],

                  searchPosition: "",
                  searchYear: "",
                  formDocs: {},
                  positionobjectdata: {},
                })
              }
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalinst} toggle={this.toggle} centered>
          <ModalHeader toggle={this.props.close}>Welcome Coach!</ModalHeader>
          <ModalBody>
            <p>
              CAA is excited to showcase our athletes to your program. Our goal
              is to match athletes that meet your criteria. All CAA athletes go
              through a strict evaluation process to make sure we are delivering
              qualified results.
            </p>
            <p>In the next screen</p>
            <ol>
              <li>Select your division</li>
              <li>Positions needed</li>
              <li>Graduation years needed</li>
              <li>Minimum GPA</li>
              <li>Click apply</li>
            </ol>
            <p>You will see athletes that match your criteria.</p>
            <ol>
              <li>Click on photo to open profile</li>
              <li>Interested – Lets you save the profile</li>
              <li>Back returns to your search</li>
            </ol>
          </ModalBody>
          <ModalFooter className="d-flex align-items-center justify-content-end">
            {!localStorage.getItem("dontshowagain") && (
              <div>
                <input
                  type="checkbox"
                  name="dontshow"
                  onClick={(e) => localStorage.setItem("dontshowagain", "1")}
                />
                <label>&nbsp; Don't Show Me Again</label>
              </div>
            )}
            <Button
              color="secondary"
              onClick={(e) => this.closeInst()}
              style={{ textAlign: "right" }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modal} toggle={this.toggle} centered>
          <ModalHeader toggle={this.props.close}>Checkout</ModalHeader>
          <ModalBody>
            <div>
              CAA will email you a list of interested athletes and links to
              their profiles. Need more help call Mike Orchard at 407-489-7509
            </div>
            <FormGroup>
              <Label htmlFor="backdrop">Email</Label>{" "}
              <Input
                type="text"
                name="email"
                id="email"
                onChange={(e) => this.setState({ coachEmail: e.target.value })}
              ></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => this.sendMicrositeMail()}>
              Send
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="player_Listing plupright">
          <div className="container">
            <div className="playerlisting_bg">
              <div className="header-btn-fixed">
                <div className="d-flex justify-content-end mb-2">
                  <a
                    href="javascript:void(0)"
                    onClick={(e) => this.setState({ modalinst: true })}
                  >
                    How to find players?
                  </a>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="pb-0">Player List</h3>{" "}
                  <button
                    className="btn btn-info"
                    onClick={(e) =>
                      this.setState({
                        showFilter: true,
                        searchDivision: localStorage.getItem("searchDivision")
                          ? JSON.parse(localStorage.getItem("searchDivision"))
                          : [],
                        searchPosition: localStorage.getItem(
                          "positionobjectdata"
                        )
                          ? localStorage.getItem("positionobjectdata")
                          : "",
                        searchYear: localStorage.getItem("searchYear")
                          ? localStorage.getItem("searchYear")
                          : "",
                      })
                    }
                  >
                    Filter
                  </button>
                  <button className="btn btn-info player_microsite get_info mylist">
                    My List
                  </button>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <button
                    className="btn btn-info player_microsite get_info mylist"
                    onClick={(e) =>
                      this.props.history.push("/interestedplayerlist")
                    }
                  >
                    My List
                  </button>
                </div>
              </div>

              <div className="divssion_part">
                {(localStorage.getItem("searchDivision") ||
                  localStorage.getItem("positionobjectdata") ||
                  localStorage.getItem("searchYear") ||
                  localStorage.getItem("unweghtedgpa")) && (
                  <div className="selectedFilterRow">
                    <h4>Selected Filter</h4>
                    <div className="">
                      {localStorage.getItem("searchDivision") &&
                        JSON.parse(localStorage.getItem("searchDivision"))
                          .length > 0 && (
                          <div className="filter_section">
                            <h4>Division</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("searchDivision") &&
                                  JSON.parse(
                                    localStorage.getItem("searchDivision")
                                  ).indexOf("d1") >= 0
                                    ? " Division 1"
                                    : ""}
                                  {localStorage.getItem("searchJV") &&
                                  JSON.parse(
                                    localStorage.getItem("searchJV")
                                  ).indexOf("d1") >= 0
                                    ? "JV"
                                    : ""}
                                  {localStorage.getItem("searchDivision") &&
                                  JSON.parse(
                                    localStorage.getItem("searchDivision")
                                  ).indexOf("d2") >= 0
                                    ? " Division 2"
                                    : ""}
                                  {localStorage.getItem("searchJV") &&
                                  JSON.parse(
                                    localStorage.getItem("searchJV")
                                  ).indexOf("d2") >= 0
                                    ? "JV"
                                    : ""}
                                  {localStorage.getItem("searchDivision") &&
                                  JSON.parse(
                                    localStorage.getItem("searchDivision")
                                  ).indexOf("d3") >= 0
                                    ? " Division 3"
                                    : ""}
                                  {localStorage.getItem("searchJV") &&
                                  JSON.parse(
                                    localStorage.getItem("searchJV")
                                  ).indexOf("d3") >= 0
                                    ? "JV"
                                    : ""}
                                  {localStorage.getItem("searchDivision") &&
                                  JSON.parse(
                                    localStorage.getItem("searchDivision")
                                  ).indexOf("jc") >= 0
                                    ? " JC"
                                    : ""}
                                  {localStorage.getItem("searchJV") &&
                                  JSON.parse(
                                    localStorage.getItem("searchJV")
                                  ).indexOf("jc") >= 0
                                    ? "JV"
                                    : ""}
                                  {localStorage.getItem("searchDivision") &&
                                  JSON.parse(
                                    localStorage.getItem("searchDivision")
                                  ).indexOf("naia") >= 0
                                    ? " NAIA"
                                    : ""}
                                  {localStorage.getItem("searchJV") &&
                                  JSON.parse(
                                    localStorage.getItem("searchJV")
                                  ).indexOf("naia") >= 0
                                    ? "JV"
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("positionobjectdata") &&
                        localStorage.getItem("positionobjectdata") && (
                          <div className="filter_section">
                            <h4>Position</h4>
                            <div className="d-flex">
                              {this.state.positions &&
                              this.state.positions.length
                                ? this.state.positions.map((t, s) => {
                                    if (
                                      localStorage.getItem(
                                        "positionobjectdata"
                                      ) &&
                                      localStorage
                                        .getItem("positionobjectdata")
                                        .split(",")
                                        .indexOf(t.value) != -1
                                    ) {
                                      return (
                                        <div key={s} classname="filter">
                                          <div className="selectedFilter">
                                            {t.key}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                                : null}
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("searchYear") &&
                        localStorage.getItem("searchYear") && (
                          <div className="filter_section">
                            <h4>Year</h4>
                            <div className="d-flex">
                              {this.state.years && this.state.years.length
                                ? this.state.years.map((v, u) => {
                                    if (
                                      localStorage.getItem("searchYear") &&
                                      localStorage
                                        .getItem("searchYear")
                                        .indexOf(v) != -1
                                    ) {
                                      return (
                                        <div key={u} classname="filter">
                                          <div className="selectedFilter">
                                            {v}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })
                                : null}
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("unweghtedgpa") &&
                        localStorage.getItem("unweghtedgpa") && (
                          <div className="filter_section">
                            <h4>Minimum GPA </h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("unweghtedgpa")}
                                  {/* {localStorage.getItem("unweghtedgpamax")} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("minHeight") &&
                        localStorage.getItem("minHeight") && (
                          <div className="filter_section">
                            <h4>Height</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {new_ht}
                                  {/* {localStorage.getItem("maxHeight")} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("minWeight") &&
                        localStorage.getItem("minWeight") && (
                          <div className="filter_section">
                            <h4>Weight</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("minWeight")}
                                  {/* {localStorage.getItem("maxWeight")} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("act") &&
                        localStorage.getItem("act") && (
                          <div className="filter_section">
                            <h4>Minimum ACT</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("act")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("sat") &&
                        localStorage.getItem("sat") && (
                          <div className="filter_section">
                            <h4>Minimum SAT</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("sat")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("rebounds_per_game") &&
                        localStorage.getItem("rebounds_per_game") && (
                          <div className="filter_section">
                            <h4>Minimum rebounds per game</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("rebounds_per_game")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("assists_per_game") &&
                        localStorage.getItem("assists_per_game") && (
                          <div className="filter_section">
                            <h4>Minimum assists per game</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("assists_per_game")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("steals_per_game") &&
                        localStorage.getItem("steals_per_game") && (
                          <div className="filter_section">
                            <h4>Minimum steals per game</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("steals_per_game")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("blocks_per_game") &&
                        localStorage.getItem("blocks_per_game") && (
                          <div className="filter_section">
                            <h4>Minimum blocks per game</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("blocks_per_game")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("field_goal") &&
                        localStorage.getItem("field_goal") && (
                          <div className="filter_section">
                            <h4>Minimum field per game</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("field_goal")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("pt_fg") &&
                        localStorage.getItem("pt_fg") && (
                          <div className="filter_section">
                            <h4>Minimum 3PT FG%</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("pt_fg")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("free_throw") &&
                        localStorage.getItem("free_throw") && (
                          <div className="filter_section">
                            <h4>Minimum Free Throw %</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("free_throw")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("points_per_game") &&
                        localStorage.getItem("points_per_game") && (
                          <div className="filter_section">
                            <h4>Minimum points per game</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("points_per_game")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("major") &&
                        localStorage.getItem("major") && (
                          <div className="filter_section">
                            <h4>Planned College Major</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("major")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("stateCode") &&
                        localStorage.getItem("stateCode") && (
                          <div className="filter_section">
                            <h4>State</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("stateCode")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("schoolsize") &&
                        localStorage.getItem("schoolsize") && (
                          <div className="filter_section">
                            <h4>School Size</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("schoolsize")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("slg") &&
                        localStorage.getItem("slg") && (
                          <div className="filter_section">
                            <h4>SLG</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("slg")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("avg") &&
                        localStorage.getItem("avg") && (
                          <div className="filter_section">
                            <h4>AVG</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("avg")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("obp") &&
                        localStorage.getItem("obp") && (
                          <div className="filter_section">
                            <h4>OBP</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("obp")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("time") &&
                        localStorage.getItem("time") && (
                          <div className="filter_section">
                            <h4>60 Time</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("time")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("velo") &&
                        localStorage.getItem("velo") && (
                          <div className="filter_section">
                            <h4>Arm Velo</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("velo")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("rh") &&
                        localStorage.getItem("rh") && (
                          <div className="filter_section">
                            <h4>Hitters</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  Right Handed
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("lh") &&
                        localStorage.getItem("lh") && (
                          <div className="filter_section">
                            <h4>Hitters</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  Left Handed
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("poptime") &&
                        localStorage.getItem("poptime") && (
                          <div className="filter_section">
                            <h4>Pop Time</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("poptime")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("strike") &&
                        localStorage.getItem("strike") && (
                          <div className="filter_section">
                            <h4>Strikeouts</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("strike")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("ip") &&
                        localStorage.getItem("ip") && (
                          <div className="filter_section">
                            <h4>Innings Pitched</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("ip")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("era") &&
                        localStorage.getItem("era") && (
                          <div className="filter_section">
                            <h4>ERA</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("era")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("velocity") &&
                        localStorage.getItem("velocity") && (
                          <div className="filter_section">
                            <h4>Fastball Velocity</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  {localStorage.getItem("velocity")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {localStorage.getItem("setRHP") &&
                        localStorage.getItem("setRHP") && (
                          <div className="filter_section">
                            <h4>RHP</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  Right Handed
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {localStorage.getItem("setLHP") &&
                        localStorage.getItem("setLHP") && (
                          <div className="filter_section">
                            <h4>LHP</h4>
                            <div className="d-flex">
                              <div classname="filter">
                                <div className="selectedFilter">
                                  Left Handed
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}

                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="year_listplayer">
                      <div className="">
                        <InfiniteScroll
                          dataLength={
                            this.state.playerList &&
                            this.state.playerList.length
                              ? this.state.playerList.length
                              : ""
                          }
                          next={this.fetchMoreData}
                          hasMore={true}
                          pullDownToRefresh
                          pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8595; Pull down to refresh
                            </h3>
                          }
                          releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8593; Release to refresh
                            </h3>
                          }
                          refreshFunction={this.fetchMoreData}
                        >
                          <div className="infscroll">
                            <div className="row">
                              {this.state.playerList &&
                              this.state.playerList.length ? (
                                this.state.playerList.map((k, j) => {
                                  const userInitials =
                                    k && k.full_name
                                      ? k.full_name.charAt(0).toUpperCase()
                                      : "";
                                  return (
                                    <div
                                      className="col-lg-3 col-md-6 col-sm-6"
                                      key={j}
                                    >
                                      <div className="player_list">
                                        <div className="player_listimage">
                                          {this.state.playerMicrositeIds &&
                                            this.state.playerMicrositeIds
                                              .length &&
                                            this.state.playerMicrositeIds.indexOf(
                                              k.id
                                            ) != -1 && (
                                              // <div className="pinterest">
                                              //   Interested
                                              // </div>
                                              <div className="arrow_box pinterest">
                                                <p className="lbl-int">
                                                  Interested
                                                </p>
                                              </div>
                                            )}

                                          <div className="player_check">
                                            <input
                                              ref={this.textInput}
                                              checked={
                                                this.state.playerMicrositeIds &&
                                                this.state.playerMicrositeIds
                                                  .length &&
                                                this.state.playerMicrositeIds.indexOf(
                                                  k.id
                                                ) != -1
                                                  ? "checked"
                                                  : ""
                                              }
                                              className="form-check-input"
                                              type="checkbox"
                                              value={k.id}
                                              name={k.id}
                                              id={"ch" + k.id}
                                              onChange={(e) =>
                                                this.handleChangePlayer(e, k.id)
                                              }
                                            />
                                          </div>
                                          <div className="lbl-new">
                                            <label>Interest</label>
                                          </div>
                                          <NavLink to={"/playermicro/" + k.id}>
                                            <img
                                              className="player_Img"
                                              src={
                                                k.profile_pic_url
                                                  ? k.profile_pic_url
                                                  : `${placehold}/400x400/?text=${userInitials}`
                                              }
                                              alt={k.full_name}
                                            />
                                          </NavLink>
                                        </div>
                                        <h4 className="player_name">
                                          {k.full_name}
                                        </h4>
                                        <div className="player_listtxt">
                                          <ul>
                                            <li>
                                              Graduation Year :{" "}
                                              <span> {k.graduation_year} </span>
                                            </li>
                                            <li>
                                              Primary Position :
                                              <span>
                                                {k.player_details &&
                                                k.player_details
                                                  .primary_position
                                                  ? k.player_details
                                                      .primary_position
                                                  : ""}
                                              </span>
                                            </li>
                                            <li>
                                              High School Name :{" "}
                                              <span>{k.high_school_name}</span>
                                            </li>
                                            <li>
                                              High School City :{" "}
                                              <span>{k.high_school_city}</span>
                                            </li>
                                            <li>
                                              High School State :{" "}
                                              <span>{k.high_school_state}</span>
                                            </li>
                                            <li>
                                              Height/Weight :{" "}
                                              <span>
                                                {k.height} / {k.weight}
                                              </span>
                                            </li>
                                            <li>
                                              R/L Handed :{" "}
                                              <span>
                                                {k.player_details &&
                                                k.player_details.r_l_handed
                                                  ? k.player_details.r_l_handed
                                                  : ""}
                                              </span>
                                            </li>
                                            <li>
                                              GPA :{" "}
                                              <span>
                                                {k.player_details &&
                                                k.player_details.unweighted_gpa
                                                  ? k.player_details
                                                      .unweighted_gpa
                                                  : ""}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="c0l-lg-12 col-md-12">
                                  No Records Found!
                                </div>
                              )}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            Copyright © College Athlete Advantage LLC.
          </div>
        </div>
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Playerlisting);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
